import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import { Button, RichText, LinkType } from '../shared'

import {
  desktopVw,
  desktopBreakpoint,
  fonts,
  colors,
  mobileVw,
} from '../../styles'

export const Dropdown = ({ store }) => {
  const [isOpen, setMenu] = useState(false)
  return (
    <Wrapper>
      <Menu isOpen={isOpen}>
        <FlexItem>
          {store.featuredItem.address.map((word, i) => (
            <StyledSpan key={i}>{word}</StyledSpan>
          ))}
          <StyledLink
            style={{ cursor: 'pointer' }}
            href={`${store.featuredItem.googleMapsLink}`}
            alt='Ce lien va ouvrir une nouvelle fenêtre: google maps store address'
          >
            Voir Sur la carte
          </StyledLink>
          <StyledSpan>
            Téléphone: <br className='desktopOnly' />
            {store.featuredItem.telephone}
          </StyledSpan>
        </FlexItem>
        <FlexItem>
          <StyledSpan>
            <RichText json={store.featuredItem.moreInformation} />
          </StyledSpan>
        </FlexItem>
        <FlexItem>
          <StyledTitle>{store.featuredItem.title}</StyledTitle>
          <StyledSpan>
            <RichText json={store.featuredItem.aboutInformation} />
          </StyledSpan>
        </FlexItem>
      </Menu>
      <div style={{ textAlign: 'center' }}>
        <StyledButton
          text={isOpen ? "moins d'informations" : "plus d'informations"}
          type={isOpen ? 'transparent' : null}
          onClick={() => setMenu(!isOpen)}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Menu = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : ' none')};
  flex-direction: column;
  margin-top: ${mobileVw(8)};

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${desktopVw(50)};
    margin-top: ${desktopVw(8)};
  }
`

const FlexItem = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    max-width: ${desktopVw(350)};
    padding-right: ${desktopVw(15)};
    &:last-of-type {
      padding-right: ${desktopVw(0)};
    }
  }
`

const StyledSpan = styled.span`
  display: block;
  width: 100%;
  font-size: ${mobileVw(16)};
  color: ${colors.white}!important;
  display: block !important;
  margin-bottom: ${mobileVw(5)};
  white-space: pre-wrap;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(20)};
    margin-bottom: 0;

    p {
      margin: 0;
      padding-bottom: ${desktopVw(40)};
    }
  }
`

const StyledTitle = styled.h2`
  color: ${colors.mailleGold};
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(18)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(24)};
    padding-bottom: ${desktopVw(13)};
  }
`

const StyledLink = styled.a`
  text-transform: uppercase;
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(20)};
  letter-spacing: ${mobileVw(1.6)};
  margin: ${mobileVw(20)} auto ${mobileVw(29)};
  color: ${colors.white}!important;
  display: block !important;

  &::after {
    content: '';
    display: block;
    width: ${mobileVw(104)};
    background-color: ${colors.white};
    height: ${mobileVw(1)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(20)};
    margin: ${desktopVw(23)} auto ${desktopVw(36)};
    letter-spacing: ${desktopVw(1.6)};
    line-height: ${desktopVw(16)};

    &::after {
      margin-top: ${desktopVw(5)};
      width: ${desktopVw(125)};
      height: ${desktopVw(1)};
    }
  }
`

const StyledButton = styled(Button)`
  margin-top: ${mobileVw(8)};
  @media (min-width: ${desktopBreakpoint}) {
    max-width: ${desktopVw(350)};
    margin-top: ${desktopVw(8)};
  }
`
