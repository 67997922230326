import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CheckBox } from './checkBox'

import {
  desktopVw,
  mobileVw,
  desktopBreakpoint,
  fonts,
  colors,
} from '../../styles'

export const Menu = ({
  categories,
  value,
  getTags,
  title,
  uncheckBoxes,
  openMobileMenu,
}) => {
  const [search, setSearch] = useState([])

  const getSearchTerm = (searchTerm) => {
    if (searchTerm) {
      if (searchTerm.indexOf('remove') === -1) {
        setSearch([...search, searchTerm])
      } else {
        const searchTermOut = searchTerm.replace('remove', '')
        const newSearch = search.filter((term) => term !== searchTermOut)
        setSearch(newSearch)
      }
    }
  }

  useEffect(() => {
    getTags(search)
  }, [search])

  useEffect(() => {
    uncheckBoxes ? setSearch([]) : null
  }, [uncheckBoxes])

  return (
    <Wrapper openMenu={openMobileMenu}>
      <StyledTitle>{title}</StyledTitle>
      {categories.map(({ value, title, subcategory }, i) => (
        <div key={i}>
          <div>
            <CheckBox
              handleSearchTerm={getSearchTerm}
              value={value}
              title={title || value}
              mainCategory
              uncheckBoxes={uncheckBoxes}
            />
            {subcategory
              ? subcategory.map((subcategory, j) => (
                  <CheckBox
                    key={i + subcategory.title}
                    handleSearchTerm={getSearchTerm}
                    value={subcategory.value}
                    uncheckBoxes={uncheckBoxes}
                    title={
                      subcategory.title ? subcategory.title : subcategory.value
                    }
                    mainCategory={false}
                  />
                ))
              : null}
          </div>
        </div>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: ${(props) => (props.openMenu ? 'block' : 'none')};
  background-color: ${colors.white};
  padding: ${mobileVw(16)} 0 ${mobileVw(70)};
  margin: 0;
  position: absolute;
  z-index: 999;
  width: 100%;

  > div {
    margin-left: ${mobileVw(16)};
  }

  li {
    list-style-type: none;
  }
  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    background-color: inherit;

    > div {
      margin-left: 0;
    }
  }
`

const StyledTitle = styled.h3`
  display: none;
  text-transform: uppercase;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
    font-size: ${desktopVw(22)};
    letter-spacing: ${desktopVw(2.2)};
    line-height: ${desktopVw(24)};
    margin-bottom: ${desktopVw(18)};
  }
`
