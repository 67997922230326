import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Image from 'gatsby-image'

import {
  HeroBlock,
  FeaturedItemBlock,
  SEO,
  Decoration,
  RichText,
  Icon,
} from '../components/shared'
import { FeaturedVideoWrapper } from "../templates/LaMaisonMaille"
import { Dropdown } from '../components/boutiqueCollection'
import VideoSchema from '../components/shared/VideoSchema'
import {
  mobileVw,
  desktopVw,
  fonts,
  colors,
  desktopBreakpoint,
} from '../styles'

const Stores = ({ location, data: { contentfulPageStores } }) => {
  const {
    storesVideo,
    heroblock,
    stores,
    decorationMobile,
    decorationDesktop,
    informationGroups,
    seoDescription,
    seoTitle,
  } = contentfulPageStores

  return (
    <StyledStoresSection>
      <SEO
        description={seoDescription}
        location={location}
        title={seoTitle}
        pageType='Stores'
      />
        <VideoSchema 
            schema={{
            "@context": "http://schema.org/",
            "@type": "ItemList",
            "itemListElement": [
                storesVideo && {"@type": "VideoObject",
                'name': seoTitle && seoTitle,
                'description': seoDescription && seoDescription,
                "uploadDate": "2020-07-19",
                "thumbnailUrl": storesVideo.featuredItem.poster.fluid.src,
                "contentUrl": storesVideo.featuredItem.videoMp4.file.url
                },
                heroblock && {"@type": "VideoObject",
                'name': seoTitle && seoTitle,
                'description': seoDescription && seoDescription,
                "uploadDate": "2020-07-19",
                "thumbnailUrl": heroblock.video.poster.fluid.src,
                "contentUrl": heroblock.video.videoMp4.file.url
                },
              ]
            }}
      />
      <HeroWrapper>
        <HeroBlock
          zIndex={2}
          marginTitle={[44, 10]}
          data={{
            ...heroblock,
            subtitle: null,
          }}
        />
      </HeroWrapper>
      <div>
        {informationGroups.map(
          ({ title, subtitle, theme, informationBlocks, decorations }, i) => (
            <div key={i}>
              <InformationGroupWrapper theme={theme}>
                <StyledTitle>{title}</StyledTitle>
                {subtitle ? <StyledSubtitle>{subtitle}</StyledSubtitle> : null}
                <InformationBlockGallery theme={theme}>
                  {informationBlocks.map((block, i) => (
                    <BlockWrapper key={i}>
                      <StyledImage
                        display={theme == 'light'}
                        fluid={block.image.fluid}
                        alt={block.image.title}
                      />
                      {block.title ? (
                        <h2>
                          {block.title.length > 1 ? (
                            block.title.map((word, i) => (
                              <span key={i}>{word}</span>
                            ))
                          ) : (
                            <span>{block.title}</span>
                          )}
                        </h2>
                      ) : null}
                      <Description>
                        {block.name !== 'Paiement securises' ? (
                          <RichText json={block.description} />
                        ) : (
                          <div className='security-section'>
                            <p>
                              Profitez de nos nouveaux services en toute
                              sécurité :
                            </p>
                            <p>
                              - En &nbsp;
                              <Icon name='lock' />
                              <b
                                style={{
                                  fontFamily: `${fonts.tivoli}`,
                                  fontSize: '1.125rem',
                                }}
                              >
                                &nbsp;3D SECURE &nbsp;
                              </b>
                              à distance pour le Call &amp; Collect
                            </p>
                            <p>
                              - Par &nbsp;
                              <Icon name='applepay2' /> &nbsp;
                              <Icon name='googlepay' /> &nbsp;
                              <Icon name='samsungpay' /> &nbsp; sans plafond
                            </p>
                          </div>
                        )}
                      </Description>

                      <StyledImage
                        alt={block.image.title}
                        display={theme == 'dark'}
                        fluid={block.image.fluid}
                      />
                    </BlockWrapper>
                  ))}
                </InformationBlockGallery>
              </InformationGroupWrapper>
              <Container>
                {decorations?.map((decoration, i) => (
                  <StyledDecoration key={i} data={decoration} />
                ))}
              </Container>
            </div>
          ),
        )}
      </div>
      {/*Added boutique videos  */}
      <FeaturedVideoWrapper>
        <FeaturedItemBlock data={storesVideo} title={storesVideo.title} hideRating />
      </FeaturedVideoWrapper>
      {stores.map((store, i) => (
        <FeaturedItemWrapper key={i}>
          <FeaturedItemBlock
            data={store}
            title={store.title}
            hideButton
            hideRating
            hideSubtitle
          />
          <Dropdown store={store} />
        </FeaturedItemWrapper>
      ))}
      <BlackBlock />
      <Image
        className='mobileOnly'
        fluid={decorationMobile.image.fluid}
        alt={decorationMobile.image.title}
      />
      <Image
        className='desktopOnly'
        fluid={decorationDesktop.image.fluid}
        alt={decorationDesktop.image.title}
        style={{ maxHeight: `${desktopVw(370)}` }}
      />
    </StyledStoresSection>
  )
}

const StyledStoresSection = styled.div`
  overflow: hidden;
`

const HeroWrapper = styled.div`
  h1 {
    font-size: ${mobileVw(30)};
    line-height: 1;
  }
  padding-bottom: 0;
  }
  margin-bottom:  ${mobileVw(-50)};

  @media (min-width: ${desktopBreakpoint}) {
    button {
      display: block;
    }
    h1 {
      font-size: ${desktopVw(70)};
      line-height: 1;
    }
    margin-bottom:  ${desktopVw(-50)};

  }
`

const Description = styled.div`
  p {
    margin: 0;
  }
  .security-section {
    p {
      margin: ${mobileVw(5)};
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  @media (min-width: ${desktopBreakpoint}) {
    min-height: ${desktopVw(100)};
    .security-section {
      p {
        margin: ${desktopVw(5)};
      }
    }
  }
`

const BlackBlock = styled.div`
  height: ${mobileVw(70)};
  background-color: ${colors.black};
  width: 100%;
  margin-top: ${mobileVw(-35)};
  margin-bottom: ${mobileVw(-60)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(318)};
    margin-bottom: ${desktopVw(-170)};
  }
`
const StyledTitle = styled.h2`
  font-size: ${mobileVw(25)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(34)};
    max-width: ${desktopVw(500)};
  }
`
const StyledSubtitle = styled.h2`
  margin: ${mobileVw(10)} auto 0;
  font-size: ${mobileVw(16)};
  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(10)} auto 0;
    font-size: ${desktopVw(20)};
  }
`

const Container = styled.div`
  position: relative;
  width: 100vw;
  z-index: 999;
  top: 0;
  left: 0;
`

const StyledDecoration = styled(Decoration)``

const InformationGroupWrapper = styled.div`
  position: relative;
  background-color: ${(props) =>
    props.theme == 'dark' ? `${colors.black}` : `${colors.white}`};
  padding: ${mobileVw(32)} ${mobileVw(16)};
  color: ${(props) =>
    props.theme == 'dark' ? `${colors.white}` : `${colors.black}`};

  ${StyledTitle}, ${StyledSubtitle} {
    color: ${(props) =>
    props.theme == 'dark' ? `${colors.white}` : `${colors.mailleGold}`};
    font-family: ${fonts.splandor};
  }

  h2 {
    font-family: ${fonts.splandor};
    color: ${colors.mailleGold};
    margin-bottom: ${mobileVw(10)};
    span {
      &:first-of-type {
        padding-right: ${mobileVw(5)};
        color: ${colors.white};
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(110)} ${desktopVw(200)} ${desktopVw(80)};
    h2 {
      font-size: ${desktopVw(34)};
      margin-bottom: ${desktopVw(20)};
      span {
        &:first-of-type {
          padding-right: ${desktopVw(5)};
        }
      }
    }
  }
`

const InformationBlockGallery = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${desktopBreakpoint}) {
    flex-direction: row;
    padding: ${(props) =>
    props.theme == 'dark' ? `${desktopVw(50)} 0 0` : `0 0 ${desktopVw(30)}`};
  }
`

const StyledImage = styled(Image)`
  width: auto;
  height: ${mobileVw(80)};
  img {
    padding: ${mobileVw(10)};
    object-fit: contain !important;
  }
  display: ${(props) => (props.display ? `flex` : `none`)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(130)};
    img {
      padding: ${desktopVw(20)};
    }
  }
`

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${mobileVw(15)};
  :last-of-type {
    h2 {
      :last-of-type {
        span {
          &:first-of-type {
            padding-right: 0;
          }
        }
      }
    }
  }

  a {
    text-decoration: underline;
  }
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(420)};
    margin-top: 0;
    margin-bottom: ${desktopVw(30)};
  }
`

const FeaturedItemWrapper = styled.div`
  background-color: ${colors.black};
  padding: ${mobileVw(30)} ${mobileVw(16)} ${mobileVw(16)};

  .featured-image {
    height: ${mobileVw(264)};
    img {
      object-position: 65% 50% !important;
    }
  }

  h2 {
    font-size: ${mobileVw(22)};
    line-height: ${mobileVw(23)};
    padding-bottom: ${mobileVw(5)};

    span {
      color: ${colors.mailleGold};
      padding-bottom: ${mobileVw(2)};
      :nth-child(1) {
        color: ${colors.white};
        padding-right: ${mobileVw(5)};
        display: inline-block;
      }
      :nth-child(2) {
        display: inline-block;
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVw(-50)} auto 0;
    padding: ${desktopVw(110)} ${desktopVw(260)} 0;

    h2 {
      font-size: ${desktopVw(34)};
      padding: 0;
      max-width: ${desktopVw(600)};
      line-height: ${desktopVw(40)};

      span {
        padding-bottom: 0;
        :nth-child(1) {
          padding-right: ${desktopVw(8)};
        }
      }
    }

    .featured-image {
      margin-top: 0;
      width: 100%;
      max-height: ${desktopVw(450)};
      img {
        object-position: center center !important;
      }
    }
  }
`

export const stores = graphql`
  query Stores($id: String!, $node_locale: String!) {
    contentfulPageStores(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      storesVideo {
        title
        buttonText
        featuredItem {
          ... on ContentfulComponentVideo {
            name
            poster {
              fluid {
                src
              }
            }
            videoMov {
              file {
                url
              }
            }
            videoMp4 {
              file {
                url
              }
            }
          }
        }
      }
      seoDescription
      seoTitle
      id
      slug
      stores {
        title
        buttonText
        featuredItem {
          ... on ContentfulStore {
            thumbnailImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            title
            slug
            address
            telephone
            googleMapsLink
            aboutInformation {
                raw
            }
            moreInformation {
                raw
            }
          }
        }
      }
      decorationMobile {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      decorationDesktop {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      heroblock {
        title
        breadcrumbsTitle
        background
        subtitle
        video {
          name
          poster {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          videoMov {
            file {
              url
            }
          }
          videoMp4 {
            file {
              url
            }
          }
        }
        breadcrumbs {
          title
          link {
            ...linkTarget
          }
        }
        richDescription {
            raw
        }
        imagesSlider {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decoration {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      informationGroups {
        theme
        title
        subtitle
        decorations {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
        informationBlocks {
          name
          title
          description {
            raw
          }
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
        }
      }
    }
  }
`

export default Stores
