import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Check } from '../shared/icons'

import {
  desktopVw,
  letterSpacing,
  desktopBreakpoint,
  mobileVw,
  colors,
  fonts,
} from '../../styles'

import { useFilterAnalytics } from '../../hooks'

export const CheckBox = ({
  value,
  title,
  mainCategory,
  handleSearchTerm,
  uncheckBoxes,
}) => {
  const [selected, setSelection] = useState(false)
  const [searchTerm, setSearchTerm] = useState(null)

  const handleClick = ({ value }) => {
    setSelection(!selected)
    if (!selected) {
      setSearchTerm(value)
      useFilterAnalytics(value)
    } else {
      setSearchTerm(`${value}remove`)
    }
  }

  const handleUncheck = () => {
    uncheckBoxes ? setSelection(false) : null
    setSearchTerm(null)
  }

  useEffect(() => {
    {
      handleSearchTerm(searchTerm)
    }
  }, [searchTerm])

  useEffect(() => {
    {
      handleUncheck()
    }
  }, [uncheckBoxes])

  return (
    <Option onClick={() => handleClick({ value })} addMargin={!mainCategory}>
      <Button isSelected={selected}>
        <StyledCheck isSelected={selected} />
      </Button>
      {mainCategory ? (
        <MainCategory isSelected={selected}>{title}</MainCategory>
      ) : (
          <Subcategory isSelected={selected}>{title.toLowerCase()}</Subcategory>
        )}
    </Option>
  )
}

const MainCategory = styled.li`
  text-transform: uppercase;
  font-family: ${fonts.tivoli};
  font-size: ${mobileVw(18)};
  color: ${(props) =>
    props.isSelected ? `${colors.mailleGold}` : `${colors.black}`};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVw(21)};
    font-size: ${desktopVw(20)};
  }
`

const Option = styled.div`
  padding: ${mobileVw(15)} 0;
  display: flex;
  align-items: flex-start;
  margin-left: ${(props) => (props.addMargin ? `${mobileVw(32)}` : `0`)};
  border-bottom: solid ${mobileVw(1)} ${colors.mediumGrey};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    margin-left: ${(props) => (props.addMargin ? `${desktopVw(32)}` : `0`)};
    border-bottom: none;
  }
`

const Button = styled.button`
  margin-right: ${mobileVw(16)};
  width: ${mobileVw(20)};
  height: ${mobileVw(20)};
  position: relative;
  border: ${(props) =>
    props.isSelected
      ? `solid ${mobileVw(1)} ${colors.mailleGold}`
      : `solid ${mobileVw(1)} ${colors.black}`};

  @media (min-width: ${desktopBreakpoint}) {
    margin-right: ${desktopVw(16)};
    width: ${desktopVw(16)};
    height: ${desktopVw(16)};
    border: ${(props) =>
    props.isSelected
      ? `solid ${desktopVw(1)} ${colors.mailleGold}`
      : `solid ${desktopVw(1)} ${colors.black}`};
  }
`

const Subcategory = styled.li`
  font-family: ${fonts.mrs};
  color: ${(props) =>
    props.isSelected ? `${colors.mailleGold}` : `${colors.black}`};
  text-transform: capitalize;

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: ${desktopVw(25)};
  }
`

const StyledCheck = styled(Check).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})`
  display: ${(props) => (props.isSelected ? 'block' : 'none')};
  width: ${mobileVw(13)};
  stroke: ${colors.mailleGold};
  stroke-width: ${mobileVw(3)};
  position: absolute;
  bottom: ${mobileVw(2)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(13)};
    stroke-width: ${desktopVw(3)};
    bottom: ${desktopVw(2)};
  }
`
